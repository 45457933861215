$font-main: 'VCTR Mono Web';
$fonts-path: 'https://assets.vectrotype.com/fonts/';
$font-body: 'VCTR Mono Web', Georgia, sans-serif;

$c-dark: #000;
$c-light: #fff;

$c-gray-0: #151515;
$c-gray-1: #333;
$c-gray-2: #5D5D5D;
$c-gray-3: #878787;
$c-gray-4: #B4B4B4;
$c-gray-5: #CBCBCB;
$c-gray-6: #D2D2D2;
$c-gray-7: #DADADA;
$c-gray-8: #E1E1E1;
$c-gray-9: #EDEDEA;
$c-gray-10: #F7F7F7;

$c-blue: #00b;
$c-red: #f00;

