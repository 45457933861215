html {
  font-size: 66%;
}
body {
  font-family: $font-body;
  font-size: 1.8rem;
  line-height: 1.3;
  color: $c-gray-0;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin-bottom: 0;
}
h1 {
  font-size: 2.4rem;
  margin-bottom: 0em;
  font-weight: normal;
}
h2 {
  font-size: 3.0rem;
  margin-bottom: .8em;
}
h3 {
  font-size: 2.4rem;
}
h4 {
  font-size: 1.8rem;
}
h5 {
  font-size: 1.4rem;
  font-weight: 600;
}
h6 {
  font-size: 1.35rem;
  font-weight: 600;
}
a {
  cursor: pointer;
  text-decoration: underline;
  color: $c-blue;

  &:hover {
    text-decoration: none;
  }
  &:active {
    opacity: .5;
  }
}
strong {
  font-weight: bold;
}
p {
  margin-bottom: .6em;
}
ul {
  list-style-position: outside;
  list-style-type: disc;
  padding: 0 0 0 1.1em;
  margin: 0;

  li {
    margin-bottom: .25em;
  }
}