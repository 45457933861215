
header {
  text-align: center;
  padding: 1rem;
  background-color: $c-dark;
  color: $c-light;
  margin-bottom: 4rem;
}

main, footer {
  margin-bottom: 4rem;
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem;
}

footer {
  font-size: 1.4rem;
}
.footer--section {
  margin-bottom: 2rem;
}